<template>
    <div class="page-factory-description">
        <div class="page-top">
            <div v-if="showType == 1 || isEdit">
                <div class="select-item">
                    <span class="select-title">质检人</span>
                    <span class="select-val none-select">{{name}}</span>
                </div>
                <div class="select-item" v-if="isEdit" @click="selectItem('w')">
                    <span class="select-title">故障类型</span>
                    <span
                        class="select-val"
                        v-if="warningType.name"
                    >{{warningType.name}}</span>
                    <span
                        class="select-val empty"
                        v-else
                    >请选择故障类型</span>
                </div>
                <div class="select-item" v-else>
                    <span class="select-title">故障类型</span>
                    <span
                        class="select-val none-select"
                        v-if="warningType.name"
                    >{{warningType.name || '暂无'}}{{info.reason && warningType.name == '其他' ? '(' + info.factoryReason + ')' : ''}}</span>
                    <span class="select-val none-select" v-else >{{warningType.name || '暂无'}}</span>
                </div>


                <div class="select-item" v-if="isEdit" @click="selectItem('c')">
                    <span class="select-title">处理方法</span>
                    <span
                        class="select-val"
                        v-if="actionMethods.name"
                    >{{actionMethods.name}}</span>
                    <span
                        class="select-val empty"
                        v-else
                    >请选择处理方法</span>
                </div>
                <div class="select-item" v-else>
                    <span class="select-title">处理方法</span>
                    <span
                        :class="{'select-val': true, 'none-select': !isEdit}"
                    >{{actionMethods.name || '超年限报废'}}</span>
                </div>
                <div class="page-footer">
                    <div class="select-item" v-if="isEdit" @click="selectItem('z')">
                        <span class="select-title">责任方</span>
                        <span
                            class="select-val"
                            v-if="responsibilityObj.name"
                        >{{responsibilityObj.name}}</span>
                        <span
                            class="select-val empty"
                            v-else
                        >请选择责任方</span>
                    </div>

                    <div class="select-item" v-else>
                        <span class="select-title">责任方</span>
                        <span
                            :class="{'select-val': true, 'none-select': !isEdit}"
                            v-if="responsibilityObj.name"
                        >{{responsibilityObj.name || '暂无'}}</span>
                    </div>

                    <div class="select-item">
                        <span class="select-title">质检图片和备注</span>
                    </div>
                    <div class="remark" v-if="isEdit">
                        <Field v-model="remark"
                            rows="5"
                            label="备注"
                            type="textarea"
                            placeholder="请输入备注"
                            :aria-disabled="!isEdit"
                            show-word-limit />
                    </div>
                    <div class="remark" v-else>
                        <div class="remarm-text">{{remark || '暂无'}}</div>
                    </div>
                    <div class="img-list" v-if="isEdit">
                        <Uploader v-model="fileList" :max-size="20000 * 200000" :after-read="afterRead" accept="image/*" :max-count="8"/>
                    </div>
                    <div class="img-list img-list-view" v-if="!isEdit && info.images && info.images.length">
                        <div class="img-item" @click="previewImg(index)" v-for="(url, index) in info.images" :key="url">
                            <img :src="url" alt="">
                        </div>
                    </div>
                    <div class="select-item" v-if="!isEdit">
                        <span class="select-title">质检时间</span>
                        <span class="select-val none-select">{{info.factoryTransactAt | getYMD}}</span>
                    </div>
                </div>
            </div>
            <div v-if="showType == 2">
                <div class="select-item" v-if="!isEdit && info.status == 3">
                    <span class="select-title">览宋拒绝时间</span>
                    <span class="select-val none-select">{{info.transactAt | getYMD}}</span>
                </div>
                <div class="remark" style="padding-bottom: 20px">
                    <div style="padding: 10px 0;">备注说明</div>
                    <Field v-model="info.refuseReason"
                        label="备注"
                        type="textarea"
                        placeholder="暂无备注"
                        disabled
                        show-word-limit />
                </div>
                <!-- <div class="remark" v-if="!isEdit && info.status == 3">{{info.reason || '暂无'}}</div> -->
            </div>
        </div>

        <div class="btn-submit" @click="submitOrder" v-if="isEdit">提交质检</div>

        <ActionSheet v-model="warningType.show" :actions="tagList" cancel-text="取消" close-on-click-action @select="selectWarning"/>
        <ActionSheet v-model="actionMethods.show" :actions="methodsList" cancel-text="取消" close-on-click-action @select="selectMethods"/>
        <ActionSheet v-model="responsibilityObj.show" :actions="responsibilityList" cancel-text="取消" close-on-click-action @select="selectResponsibility"/>
        <Popup v-model="isShowPopup" position="bottom" style="min-height: 40%" :close-on-click-overlay="false">
            <div class="pop-box">
                <div class="pop-header">
                    <div class="btn-back" @click="onclickPopBack">返回</div>
                    <div class="header-title">其他原因</div>
                    <div class="header-right">返回</div>
                </div>
                <div class="pop-body">
                    <Field v-model="reason"
                    rows="8"
                    type="textarea"
                    placeholder="请检单描述问题"
                    :aria-disabled="!isEdit"
                    show-word-limit />
                </div>
                <div class="pop-footer">
                    <div class="footer-btn" @click="onclickClosePop">确定</div>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
import { Toast, Field, Uploader, ActionSheet, ImagePreview, Popup, Dialog } from "vant";
import { getYMD } from '@/utils/time'
import { compressImage } from '@/lib/compressImage'
export default {
    name: "FactoryQualityOrderInfo",
    filters: {
        getYMD
    },
    data() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        let name = vm.$localStorage.getItem(USER_NAME);
        let type = 0;
        let isEdit = Number(vm.$route.query.type)
        // 1乐行质检，2览宋质检
        let showType = Number(vm.$route.query.showType)
        const responsibilityList = [
                { name: "览宋", id: 5 }
        ]
        const uuid = vm.$localStorage.getItem("uuid");
        const fType = vm.$strTool.filterFactoryType(uuid);
        if (fType == 'ruiyin') {
            responsibilityList.push({ name: "瑞银", id: 2 })
            type = 1
        }

        if (fType == 'lexing' || fType == 'wangbo') {
            responsibilityList.push({ name: "旺博", id: 3 })
            type = 2
        }
        return {
            info: { reason: '' },
            name,
            warningType: {
                show: false
            },
            actionMethods: {
                show: false
            },
            responsibilityObj: {
                show: false
            },
            tagList: [],
            type,
            methodsList: [
                { name: "整机翻新", id: 1 },
                { name: "报废(无需质检)", id: 2 },
                { name: "报废(需要质检)", id: 3 }
            ],
            responsibilityList,
            remark: '',
            fileList: [],
            isEdit,
            isShowPopup: false,
            reason: '',
            showType
        };
    },
    components: {
        Field,
        Uploader,
        ActionSheet,
        Popup,
        [ImagePreview.Component.name]: ImagePreview.Component
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        let name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem("uuid");
        const fType = vm.$strTool.filterFactoryType(uuid);
        if (!name || vm.$config.base.fList.indexOf(fType) == -1) {
            vm.$router.replace({
                path: "/factory/login"
            });
            return;
        }

        vm.loadDetail();
    },
    methods: {
        handleChange() {},
        loadDetail() {
            const vm = this;
            vm.ttid = Toast.loading({
                message: "加载中...",
                forbidClick: true,
                overlay: true,
                duration: 0
            });
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}returnFac/detail`,
                params: { uniqueId: vm.$route.params.id }
            })
                .then(res => {
                    if (res.data) {
                        const imgs = []
                        vm.info = res.data;
                        if (vm.info.status > 1) {
                            vm.info.factoryTag && (vm.warningType.name = vm.info.factoryTag || '暂无');
                            vm.name = vm.info.factoryOperator;
                            if (vm.info.handlingMethod) {
                                const aItem = vm.methodsList.filter(m => m.id == vm.info.handlingMethod)[0]
                                aItem && (vm.actionMethods.name = aItem.name)
                                vm.actionMethods.id = vm.info.handlingMethod
                            }

                            if (vm.info.responsibleParty) {
                                const list = [
                                    { name: "览宋", id: 5 },
                                    { name: "瑞银", id: 2 },
                                    { name: "旺博", id: 3 }
                                ]
                                const rItem = list.filter(r => r.id == vm.info.responsibleParty)[0]
                                rItem && (vm.responsibilityObj.name = rItem.name)
                                vm.responsibilityObj.id = vm.info.responsibleParty
                            }
                            vm.remark = vm.info.factoryRemark;
                            vm.info.images && vm.info.images.map(item => {
                                imgs.push({
                                    url: item
                                })
                            })
                            vm.fileList = imgs
                        }
                        vm.loadTagList(res.data.deviceType);
                    } else {
                        Toast.fail({
                            message: "设备编码不存在~",
                            forbidClick: true,
                            overlay: true,
                            duration: 2000
                        });
                    }
                    vm.ttid.clear();
                })
                .catch(err => {
                    console.log(err);
                    vm.ttid.clear();
                });
        },
        loadTagList(type) {
            const vm = this;
            const dType = vm.$strTool.resetDeviceType(type);
            let paramsType = (dType == 1 ? 4 : 5);
            dType == 2 && (paramsType = 16);
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}returnFac/tags`,
                params: { type: paramsType }
            }).then(res => {
                if (res.data.length) {
                    res.data.map(item => {
                        item.name = item.tagName
                        if (vm.info.factoryTag && vm.isEdit && vm.info.factoryTag == item.tagName) {
                            vm.warningType.id = item.id
                        }
                    })
                }
                vm.tagList = res.data;
            }).catch(err => {
                console.log(err);
            });
        },
        onclickPopBack() {
            this.isShowPopup = false;
            this.warningType.show = true
        },
        onclickClosePop() {
            const vm = this
            if (!vm.reason) {
                Toast('请填写问题描述')
                return
            }
            vm.isShowPopup = false
        },
        afterRead(file) {
            const vm = this
            file.status = 'uploading';
            file.message = '上传中...';
            compressImage(file.content, {
                width: 500,
                quality: 0.1
            }).then(res => {
                // vm.uploadImg(convertBase64UrlToBlob(res), file);
                vm.uploadImg(res, file);
            })
            // vm.uploadImg(file)
        },
        uploadImg(file, upFile) {
            const vm = this;
            // const f = new FormData();
            // f.append('file', file.file)
            // alert(JSON.stringify(file))
            vm.$http({
                type: "post",
                url: `${vm.$config.base.ADMIN_URL}javaApi/file/iot/qcImages?password=${vm.$config.base.password}`,
                // data: { file: f }
                data: {
                    encodeFile: file.split('base64,')[1],
                    ext: file.split(';base64')[0].split('image/')[1]
                }
            }).then(res => {
                upFile.url = res.data;
                upFile.status = 'succ';
                upFile.message = '';
            }).catch(err => {
                console.log(err);
            });
        },
        selectWarning(obj) {
            const vm = this
            vm.warningType = Object.assign({}, vm.warningType, obj)
            vm.isShowPopup = obj.tagName == '其他'
        },

        selectMethods(obj) {
            const vm = this
            vm.actionMethods = Object.assign({}, vm.actionMethods, obj)
        },

        selectResponsibility(obj) {
            const vm = this
            vm.responsibilityObj = Object.assign({}, vm.responsibilityObj, obj)
        },

        selectItem(type) {
            const vm = this
            type == 'w' && (vm.warningType.show = true)
            type == 'c' && (vm.actionMethods.show = true)
            type == 'z' && (vm.responsibilityObj.show = true)
        },

        submitOrder() {
            const vm = this
            let error = '';
            const imgList = []
            vm.fileList.length && vm.fileList.map(file => {
                file.url && imgList.push(file.url);
            })
            !vm.responsibilityObj.id && (error = '请选择责任人');
            !vm.actionMethods.id && (error = '请选择处理方法');
            !vm.warningType.id && (error = '请选择故障类型');
            vm.warningType.tagName == '其他' && !vm.reason && (error )
            !imgList.length && (error = '请上传图片')
            if (error) {
                Toast.fail({
                    message: error,
                    forbidClick: true,
                    overlay: true,
                    duration: 2000
                });
                return
            }

            const params = {
                id: vm.info.id,
                operator: vm.name,
                password: vm.$config.base.password,
                remark: vm.remark,
                responsibleParty: vm.responsibilityObj.id,
                tagId: vm.warningType.id,
                handlingMethod: vm.actionMethods.id,
                reason: vm.reason,
                images: imgList
            }

            if (vm.info.status > 1 && vm.isEdit && vm.type == 1) {
                Dialog.confirm({
                    title: "是否确认提交?",
                    message: "未完成结算的订单只支持修改一次质检记录",
                    confirmButtonColor: "#32BE32",
                    confirmButtonText: "确认提交"
                }).then(() => {
                    vm.tt = Toast.loading({
                        message: "加载中...",
                        forbidClick: true,
                        overlay: true,
                        duration: 0
                    });
                    vm.$http({
                        method: "put",
                        url: `${vm.$config.base.PROBE_BASE_URL}returnFac${vm.info.status > 1 ? '/checkInfo' : ''}`,
                        data: params
                    }).then(res => {
                        vm.tt.clear();
                        if (res.code != 1) {
                            Toast.fail({
                                message: res.message,
                                forbidClick: true,
                                overlay: true,
                                duration: 2000
                            });
                            return
                        }
                        Toast.success({
                            message: vm.info.status > 1 ? '已修改质检单' : '成功提交质检单，请耐心等待览宋确认',
                            forbidClick: true,
                            overlay: true,
                            duration: 2000
                        })
                        setTimeout(() => {
                            vm.$router.replace({
                                path: `/factory/quality/history/${vm.$route.params.id}?id=${vm.info.id}`
                            });
                        }, 2000);
                    }).catch(err => {
                        console.log(err);
                        vm.tt.clear();
                    });
                }).catch(() => {
                    console.log(123)
                });
                return
            }
            Dialog.confirm({
                title: "提示",
                message: "是否确认上传定损记录?",
                confirmButtonColor: "#32BE32"
            })
                .then(() => {
                    vm.tt = Toast.loading({
                        message: "加载中...",
                        forbidClick: true,
                        overlay: true,
                        duration: 0
                    });
                    vm.$http({
                        method: "put",
                        url: `${vm.$config.base.PROBE_BASE_URL}returnFac${vm.info.status > 1 ? '/checkInfo' : ''}`,
                        data: params
                    }).then(res => {
                        vm.tt.clear();
                        if (res.code != 1) {
                            Toast.fail({
                                message: res.message,
                                forbidClick: true,
                                overlay: true,
                                duration: 2000
                            });
                            return
                        }
                        Toast.success({
                            message: vm.info.status > 1 ? '已修改质检单' : '成功提交质检单，请耐心等待览宋确认',
                            forbidClick: true,
                            overlay: true,
                            duration: 2000
                        })
                        setTimeout(() => {
                            vm.$router.replace({
                                path: `/factory/quality/history/${vm.$route.params.id}?id=${vm.info.id}`
                            });
                        }, 2000);
                    }).catch(err => {
                        console.log(err);
                        vm.tt.clear();
                    });
                })
                .catch(() => {
                    // on cancel
                });
        },

        previewImg(index) {
            const vm = this
            ImagePreview({
                images: vm.info.images,
                startPosition: index
            });
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-factory-description {
    width: 100%;
    height: 100%;
    background: @bgColor2;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
    box-sizing: border-box;
    padding: 20px 10px 100px;
    font-size: 0.16rem;
    .page-top{
        padding: 0 10px;
    }
    .page-top,
    .page-footer {
        background: @blank;
        margin-bottom: 10px;
        .select-item {
            padding: 15px 0;
            color: @textColor4;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid @borderColor1;
            .select-title {
                font-weight: 600;
            }

            .empty, .select-val {
                color: @textColor8;
                position: relative;
                padding-right: 20px;
                &:after,&:before{
                    display: block;
                    content: ' ';
                    width: 8px;
                    height: 0px;
                    overflow: hidden;
                    font-size: 0;
                    border-bottom: 1px solid @textColor8;
                    position: absolute;
                    right: 8px;
                    transform: rotate(45deg);
                    top: 12px;
                }
                &:before {
                    transform: rotate(-45deg);
                    right: 3px;
                }
            }

            .select-val {
                color: @textColor8;
                &:after,&:before {
                    border-color: @textColor8;
                }
                &.none-select {
                    padding-right: 0;
                    &:after,&:before {
                        display: none;
                    }
                }
            }
            .empty {
                color: @textColor7;
                &:after,&:before {
                    border-color: @textColor7;
                }
            }
        }

        .remark {
            .van-field {
                border: 1px solid @borderColor2;
            }
            .van-field__label{
                display: none;
            }
            .van-field__body, .van-field__word-limit {
                font-size: 0.14rem;
            }
            .remarm-text {
                color: @textColor8;
                background: #eee;
                padding: 10px
            }
        }

        .img-list {
            padding-top: 20px;
        }
    }
    .page-footer {
        padding-bottom: 10px;
    }
    .btn-submit {
        width: 100%;
        line-height: 0.5rem;
        text-align: center;
        color: @blank;
        font-size: 0.2rem;
        // border-radius: 0.25rem;
        box-shadow: 0px 2px 10px 0px @textColor3;
        background: @textColor3;
        position: fixed;
        left: 0;
        bottom: 0;
    }
    .img-list-view {
        padding-bottom: 10px;
        .img-item {
            width: 85px;
            height: 85px;
            overflow: hidden;
            border-radius: 5px;
            display: inline-block;
            margin-right: 10px;
        }
        img {
            display: inline-block;
            width: 100px;
        }
    }

    .van-popup--bottom {
        border-radius: 10px 10px 0 0;
        .pop-box {
            padding: 10px;
            .pop-header {
                line-height: 40px;
                display: flex;
                justify-content: space-between;
                .header-right {
                    color: @blank;
                }
                .header-title {
                    font-size: 0.18rem;
                    font-weight: bold;
                }
            }
            .pop-body {
                .van-field {
                    border: 1px solid #eee;
                }
            }
            .pop-footer {
                padding: 20px 0 10px;
                .footer-btn {
                    width: 50%;
                    line-height: 0.5rem;
                    text-align: center;
                    color: @blank;
                    font-size: 0.2rem;
                    border-radius: 0.25rem;
                    box-shadow: 0px 2px 10px 0px @textColor3;
                    background: @textColor3;
                    margin: 0 auto;
                }
            }
        }
    }
    .van-field__control:disabled {
        -webkit-text-fill-color: #323233;
    }
}
</style>
